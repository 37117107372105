<template>
  <div>
    <div v-if="this.isAuthenticated">
      <KTCard ref="preview" v-bind:title="title" v-bind:example="true">
        <template v-slot:title>
          {{ title }}
        </template>
        <template v-slot:body>
          <div class="d-block text-center">
            <b-form @submit="onSubmit" @reset="onReset">
              <b-container fluid>
                <div class="row">
                  <div class="col-md-12">
                    <b-alert :show="show_alert" variant="warning">{{
                        error_surver
                      }}</b-alert>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="row">
                      <div class="col-md-5">
                        <div class="form-group text-left">
                          <fieldset aria-describedby="" class="form-group">
                            <legend
                                tabindex="-1"
                                class="bv-no-focus-ring col-form-label pt-0"
                            >
                              <h3>
                                <u>{{ $t("SURVEY.PENGGUNAAN_APLIKASI.LABEL") }}</u>
                              </h3>
                            </legend>
                            <div
                                tabindex="-1"
                                role="group"
                                class="bv-no-focus-ring"
                            >
                              <div
                                  role="radiogroup"
                                  tabindex="-1"
                                  class="bv-no-focus-ring"
                              >
                                <div
                                    class="
                                custom-control
                                custom-control-inline
                                custom-radio
                              "
                                >
                                  <input
                                      type="radio"
                                      name="kepuasan"
                                      v-model="form.kepuasan"
                                      class="custom-control-input"
                                      value="1"
                                      id="rpuas"
                                      required
                                  />
                                  <label
                                      class="custom-control-label text-light"
                                      for="rpuas"
                                  >
                                <span>
                                  <img
                                      src="media/image/01.png"
                                      style="width: 100px"
                                  /><br />
                                  {{
                                    $t("SURVEY.PENGGUNAAN_APLIKASI.OPTLABEL1")
                                  }}
                                </span>
                                  </label>
                                </div>
                                <div
                                    class="
                                custom-control
                                custom-control-inline
                                custom-radio
                              "
                                >
                                  <input
                                      type="radio"
                                      name="kepuasan"
                                      v-model="form.kepuasan"
                                      class="custom-control-input text-light"
                                      value="2"
                                      id="rnormal"
                                      required
                                  />
                                  <label
                                      class="custom-control-label text-white"
                                      for="rnormal"
                                  >
                                <span>
                                  <img
                                      src="media/image/Neutral.png"
                                      style="width: 100px"
                                  /><br />
                                  {{
                                    $t("SURVEY.PENGGUNAAN_APLIKASI.OPTLABEL2")
                                  }}
                                </span>
                                  </label>
                                </div>
                                <div
                                    class="
                                custom-control
                                custom-control-inline
                                custom-radio
                              "
                                >
                                  <input
                                      type="radio"
                                      name="kepuasan"
                                      v-model="form.kepuasan"
                                      class="custom-control-input text-light"
                                      value="0"
                                      id="rtidakpuas"
                                      required
                                  />
                                  <label
                                      class="custom-control-label text-white"
                                      for="rtidakpuas"
                                  >
                                <span>
                                  <img
                                      src="media/image/02.png"
                                      style="width: 100px"
                                  /><br />
                                  {{
                                    $t("SURVEY.PENGGUNAAN_APLIKASI.OPTLABEL3")
                                  }}
                                </span>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </fieldset>
                        </div>
                      </div>
                      <div class="col-md-7 text-left">
                        <b-form-group :label="$t('SURVEY.JENISKELAMIN.LABEL')">
                          <b-form-radio-group
                              v-model="form.gender"
                              :options="optgender"
                              name="gender"
                              required
                          ></b-form-radio-group>
                        </b-form-group>
                        <b-form-group :label="$t('SURVEY.USIA.LABEL')">
                          <b-form-radio-group
                              v-model="form.age"
                              :options="optage"
                              class="text-light"
                              name="age"
                              required
                          ></b-form-radio-group>
                        </b-form-group>
                        <b-form-group :label="$t('SURVEY.PENDIDIKAN.LABEL')">
                          <b-form-radio-group
                              v-model="form.education"
                              :options="opteducation"
                              class="text-light"
                              name="education"
                              required
                          ></b-form-radio-group>
                        </b-form-group>
                        <b-form-group
                            id="input-group-1"
                            class="row"
                            :label="$t('SURVEY.SARAN.LABEL')"
                            label-for="input-2"
                            label-class="text-white"
                            label-cols="3"
                        >
                          <b-form-textarea
                              id="advice"
                              v-model="form.advice"
                              placeholder="Enter something..."
                              rows="3"
                              max-rows="6"
                          ></b-form-textarea>
                        </b-form-group>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row"><br /></div>
                <div class="row">
                  <div class="col-md-12 text-right">
                    <b-button type="submit" class="mr-3" variant="warning">
                      {{ $t("SURVEY.BTN.LABEL") }}</b-button
                    >
                  </div>
                </div>
              </b-container>
            </b-form>
          </div>
        </template>
      </KTCard>
    </div>
    <div v-if="!this.isAuthenticated">
      <div class="card card-custom card-stretch gutter-b">
        <div class="card-body d-flex p-0">
          <div
            class="flex-grow-1 p-20 pb-40 card-rounded flex-grow-1 bgi-no-repeat"
            style="
              background-position: calc(100% + 0.5rem) bottom;
              background-size: 35% auto;
              background-image: url(media/svg/humans/custom-10.svg);
            "
          >
            <h2 class="text-dark pb-5 font-weight-bolder">
              {{ $t("COMPLAINT_RECEIPT_DATA.REGISTER_NOW_TITLE") }}
            </h2>
            <p class="text-dark-50 pb-5 font-size-h5">
              {{ $t("COMPLAINT_RECEIPT_DATA.REGISTER_NOW") }}<br />
              {{ $t("COMPLAINT_RECEIPT_DATA.REGISTER_NOW_SECOND_PARAGRAPH") }}
            </p>
            <a
              @click="registerClicked"
              class="btn btn-danger font-weight-bold py-2 px-6"
              >{{ $t("COMPLAINT_RECEIPT_DATA.REGISTER_NOW_TITLE") }}</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  SURVEY_POST,
} from "@/core/services/store/dashboard.module";
import KTCard from "@/view/content/Card.vue";
// import JwtService from "@/core/services/jwt.service";
import Swal from "sweetalert2";
// import * as axios from "axios";

export default {
  mixins: [validationMixin],
  name: "survey",
  data() {
    return {
      title : "",
      latestUpdate: Date.now(),
      bodyBgVariant: "dark",
      footerTextVariant: "dark",
      optgender: [
        { text: this.$t("SURVEY.JENISKELAMIN.OPTLABEL1"), value: 1 },
        { text: this.$t("SURVEY.JENISKELAMIN.OPTLABEL2"), value: 2 },
      ],
      optage: [
        { text: this.$t("SURVEY.USIA.OPTLABEL1"), value: 1 },
        { text: this.$t("SURVEY.USIA.OPTLABEL2"), value: 2 },
        { text: this.$t("SURVEY.USIA.OPTLABEL3"), value: 3 },
        { text: this.$t("SURVEY.USIA.OPTLABEL4"), value: 4 },
      ],
      opteducation: [
        { text: this.$t("SURVEY.PENDIDIKAN.OPTLABEL1"), value: 1 },
        { text: this.$t("SURVEY.PENDIDIKAN.OPTLABEL2"), value: 2 },
        { text: this.$t("SURVEY.PENDIDIKAN.OPTLABEL3"), value: 3 },
        { text: this.$t("SURVEY.PENDIDIKAN.OPTLABEL4"), value: 4 },
        { text: this.$t("SURVEY.PENDIDIKAN.OPTLABEL5"), value: 5 },
        { text: this.$t("SURVEY.PENDIDIKAN.OPTLABEL6"), value: 6 },
        { text: this.$t("SURVEY.PENDIDIKAN.OPTLABEL7"), value: 7 },
      ],
      form: {
        gender: "",
        age: "",
        education: "",
        advice: "",
        kepuasan: "",
        fullPage: true,
      },
      error_surver: "",
      show_alert: false,
      isLoading: false,
    };
  },
  validations: {
    form: {
      proposaltype: {
        required,
      },
      description: {
        required,
      },
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$t("MENU.SURVEY_DATA"),
        route: "survey",
      },
      { title: this.$t("MENU.SURVEY_DATA_FORM") },
    ]);
    this.title = this.$t("MENU.SURVEY_DATA_FORM");
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      const email = "";
      const values = this.form.kepuasan;
      const gender = this.form.gender;
      const age = this.form.age;
      const education = this.form.education;
      const advice = this.form.advice;

      this.$store
          .dispatch(SURVEY_POST, {
            email,
            values,
            gender,
            age,
            education,
            advice,
          })
          .then((response) => {
            if(response.message==='failed'){
              Swal.fire(
                  'Survey',
                  response.data[0],
                  'warning'
              );
              this.show_alert = true;
              this.error_surver = response.data[0];
            }else{
              this.$session.set("IsSurvey", "true");
              this.show_alert = false;
              Swal.fire(
                  'Survey',
                  'Survey successfully registered',
                  'success'
              );
              this.form.kepuasan = "";
              this.form.gender = "";
              this.form.age = "";
              this.form.education = "";
              this.form.advice = "";
            }
          })
          .catch((error) => {
            console.log(error.response);
            let keys = Object.keys(error.response.data.errors);
            let keysval = Object.values(error.response.data.errors);
            for (let i = 0; i < keys.length; i++) {
              this.error_surver = keysval[i][0];
              this.show_alert = true;
            }
          });
    },
    onReset(evt) {
      evt.preventDefault();
      // Reset our form values
      this.form.email = "";
    },
  },
  components: {
    KTCard,
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
    ]),
  }
};
</script>
